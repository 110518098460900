import React, { useState } from "react"
import Layout from "components/layout"
import Hero from "components/hero"
import { graphql } from "gatsby"
import ContactForm, { Email, Name, Other } from "components/contact-form"
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next"
import StickyMenu from "components/sticky-menu"
import { submitForm } from "utils/helpers"

const CareerPage = ({ location }) => {
  const { t } = useTranslation()
  const [isSubmitted, setSubmitted] = useState<boolean>(false)

  return (
    <Layout path={location.pathname}>
      <Helmet>
        <title>{`${t("Career")} - ${t("heroTitle")}`}</title>
        <meta property="og:title" content={t("heroTitle")} />
        <meta property="og:description" content={t("textIngress")} />
        <meta name="title" content={t("heroTitle")} />
        <meta name="description" content={t("textIngress")} />
      </Helmet>
      <StickyMenu pageSlug={"/career"} visible />
      <Hero
        title={
          <>
            <h1 className="mb-0 lowercase font-bold">{t("heroTitle")}</h1>
            <h3 className="styled w-ligature">{t("heroSubTitle")}</h3>
          </>
        }
      />
      <section
        id={t("mission")}
        className="py-20 md:py-32 bg-gradient-to-b from-white to-smokeBgLight"
      >
        <div className="container">
          <div className="row offer">
            <div className="col-span-12 col-start-1 lg:col-span-10 xl:col-span-8">
              <h2 className="font-bold uppercase mb-7">{t("textTitle")}</h2>
              <h3 className="ingress font-extralight pb-4">
                {t("textIngress")}
              </h3>
              <p>{t("textPar1")}</p>
              <p className="mb-16">{t("textPar2")}</p>
              {isSubmitted ? (
                <h3>{t("Thank you")}!</h3>
              ) : (
                <ContactForm
                  formName={"Career form"}
                  pages={[
                    props => <Name {...props} />,
                    props => <Email {...props} />,
                    props => <Other {...props} />,
                  ]}
                  initFormFields={{
                    name: "",
                    email: "",
                    message: "",
                  }}
                  onSubmit={values => {
                    submitForm(values)
                      .then(res => {
                        setSubmitted(true)

                        if (typeof window.dataLayer !== "undefined") {
                          window.dataLayer.push({
                            event: "Career page form submitted",
                          })
                        }
                      })
                      .catch(err => {
                        alert(
                          "Something went wrong, reload the page and try again."
                        )
                      })
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Careerpage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CareerPage
