import React, { useCallback, useState } from "react";
import { ArrowElbowDownLeft, ArrowCircleRight, ArrowFatLeft } from "components/svg";
import { ValidateEmail } from "utils/helpers";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FormPage } from ".";

const Email = ({togglePage, currentPage, values, setValues}: FormPage) => {
  const [isValid, setIsValid] = useState(ValidateEmail(values.email));

  const onChange = useCallback(({target}) => {
    setValues(state => ({...state, email: target.value}));
    setIsValid(ValidateEmail(target.value));
  }, []);
  const handleKeyDown = (e) => {
    if (/Enter/.test(e.key)) {
      e.preventDefault();
      togglePage(1);
    };
  };

  const { t } = useTranslation('contact');

  return (
    <div style={{position: 'relative'}} className="email-section">
      <h3 className="question-title">{t("What's your e-mail?")}</h3>
      <input autoFocus={currentPage !== 0} type="email" onKeyDown={handleKeyDown} value={values.email} name={"e-mail"} className="w-full" onChange={onChange}/>
      <label htmlFor={t("E-mail")}>{t("E-mail")}</label>
      {currentPage !== 0 ? (
        <ArrowFatLeft style={{width: '40px', height: '40px', cursor: 'pointer'}} className="svg-smokeText mt-5" onClick={() => togglePage(-1)}/>
      ) : (<div style={{height: '40px'}} className="mt-5" />)}
      {isValid && 
        <div className="absolute right-0 top-3" onClick={() => togglePage(1)}>
          <ArrowCircleRight style={{width: '40px', height: '40px'}} className="svg-smokeText cursor-pointer"/>
        </div>
      }
      {isValid && 
        <div className="absolute right-0 bottom-3 text-smokeText flex items-end justify-end ml-5 mt-2">
          <small>{t('Hit Enter')}</small>
          <ArrowElbowDownLeft style={{width: '22px', height: '22px'}} className="svg-smokeText cursor-pointer mb-0.5" />
        </div>
      }
    </div >
  );
}

export default Email;