import React, { useEffect, useState } from 'react';
import { useSpring, config, animated } from 'react-spring/web.cjs';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import { debounce, isMobileOrTablet } from 'utils/helpers';
import * as easings from "d3-ease";

const Hero = ({title}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const {heroBgDesktop, heroBgMobile} = useStaticQuery(graphql`
    query HeroQuery {
      heroBgDesktop: imageSharp(sizes: {originalName: {eq: "loading-splash.png"}}) {
        fluid(maxWidth: 2000, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      heroBgMobile: imageSharp(sizes: {originalName: {eq: "loading-splash-mobile.png"}}) {
        fluid(maxWidth: 600, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroBg = [
    heroBgMobile.fluid,
    {
      ...heroBgDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const imgLoaded = useSpring({
    transform: loading ? 'scale(1.2)' : 'scale(1)',
    config: {
      duration: 7000,
      easing: easings.easeCubicOut
    }
  });

  const [{y}, setY] = useSpring<{y: number}>(() => ({
    y: 0,
    immediate: true,
    config: config.stiff
  }));

  const onScroll = () => {
    setY({
      y: window.scrollY * 0.12,
      immediate: true,
    });
  };

  const appHeight = debounce(() => {
    if (!isMobileOrTablet()) {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
  }, 100);
  useEffect(() => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    window.addEventListener('resize', appHeight);

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [onScroll]);

  useEffect(() => {
    const timeout1 = setTimeout(() => setLoading(false), 800);

    return () => {clearTimeout(timeout1); };
  }, [setLoading])

  const {t} = useTranslation('hero');

  return (
    <section className="general-hero overflow-hidden relative">
      <div className="z-20 relative">
        <div className="container relative">
          <div className="row">
            <div className="col-span-12">
              {title}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b to-white from-whiteTrans absolute bottom-0 left-0 w-full h-1/3 z-10"/>
      <div className={"absolute w-full h-full top-0 left-0 z-0"}>
        <animated.div style={{height: '100%', willChange: 'transform', ...imgLoaded}}>
          <animated.div style={{top: 0, position: 'relative', height: '100%', width: '100%', willChange: 'transform', transform: y.interpolate((y) => (`translate3d(0,${y}px,0)`))}}>
            <Img fluid={heroBg} loading={'eager'} objectFit={'cover'} style={{height: '100%'}} objectPosition={"top"} />
          </animated.div>
        </animated.div>
      </div>
    </section>
  );
};

export default Hero;
