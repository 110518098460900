import React, { useCallback, useState } from "react"
import { ArrowFatLeft } from "components/svg"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { FormPage } from "."

const Other = ({ togglePage, currentPage, values, setValues }: FormPage) => {
  const [disabled, setDisabled] = useState<boolean>(true)
  const onChange = useCallback(({ target }) => {
    setValues(state => ({ ...state, message: target.value }))
  }, [])

  const { language } = useI18next()
  const { t } = useTranslation("contact")

  return (
    <div className="w-full message-section">
      <h3 className="question-title">{t("Message (optional)")}</h3>
      <div className="relative">
        <textarea
          id="message"
          name="message"
          rows={3}
          value={values.message}
          onChange={onChange}
          className="w-full resize-none"
        />
        <label htmlFor="message" className="text-green-300">
          {t("Write us a message")}
        </label>
      </div>
      <div className="summary">
        {Object.keys(values).map(
          key =>
            values[key] &&
            !["form-name", "language"].includes(key) && (
              <div key={key}>
                <small>{t(key)}:</small>
                <span>{values[key]}</span>
              </div>
            )
        )}
      </div>
      <div className="flex items-center justify-between mt-5">
        {currentPage !== 0 && (
          <ArrowFatLeft
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            className="svg-smokeText"
            onClick={() => togglePage(-1)}
          />
        )}
        <div className="flex items-center">
          <label
            className="text-smokeBgBlack checkbox-label mr-2 text-right leading-4 cursor-pointer text-sm"
            htmlFor="privacy"
          >
            {language === "sv" ? (
              <>
                Jag accepterar härmed{" "}
                <a href={"/privacy"} target="_blank">
                  integritetspolicy
                </a>
              </>
            ) : (
              <>
                I hereby accept the{" "}
                <a href={"/en/privacy"} target="_blank">
                  privacy policy
                </a>
              </>
            )}
          </label>
          <input
            type="checkbox"
            name="privacy"
            id="privacy"
            className="mr-2 sm:mr-5 cursor-pointer"
            onChange={({ target }) => setDisabled(!target.checked)}
          />
          <button className="send-button" type="submit" disabled={disabled}>
            {t("Send")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Other
