import React, { useCallback, useState } from "react";
import { ArrowElbowDownLeft, ArrowCircleRight, ArrowFatLeft } from "components/svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FormPage } from ".";

const CompanyName = ({togglePage, currentPage, values, setValues}: FormPage) => {
  const [isEmpty, setIsEmpty] = useState(values.company === '');

  const onChange = useCallback(({target}) => {
    setValues(state => ({...state, company: target.value}));
    target.value !== '' ? setIsEmpty(false) : setIsEmpty(true);
  }, []);

  const {t} = useTranslation('contact');
  const handleKeyDown = (e) => {
    if (/Enter/.test(e.key)) {
      e.preventDefault();
      togglePage(1);
    };
  };

  return (
    <div style={{position: 'relative'}} className="company-section">
      <h3 className="question-title">{t('Where do you work?')}</h3>
      <input autoFocus={currentPage !== 0} type="text" onKeyDown={handleKeyDown} value={values.company} name={"company"} className="w-full" onChange={onChange}/>
      <label htmlFor="name">{t('Company')}</label>
      {currentPage !== 0 ? (
        <ArrowFatLeft style={{width: '40px', height: '40px', cursor: 'pointer'}} className="svg-smokeText mt-5" onClick={() => togglePage(-1)}/>
      ) : (<div style={{height: '40px'}} className="mt-5" />)}
      {!isEmpty && 
        <div className="absolute right-0 top-3" onClick={() => togglePage(1)}>
          <ArrowCircleRight style={{width: '40px', height: '40px'}} className="svg-smokeText cursor-pointer"/>
        </div>
      }
      {!isEmpty && 
        <div className="absolute right-0 bottom-3 text-smokeText flex items-end justify-end ml-5 mt-2">
          <small>{t('Hit Enter')}</small>
          <ArrowElbowDownLeft style={{width: '22px', height: '22px'}} className="svg-smokeText cursor-pointer mb-0.5" />
        </div>
      }
    </div >
  );
}

export default CompanyName;