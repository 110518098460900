import React, { CSSProperties, useCallback, useState } from "react"
import { useTransition, animated } from "react-spring/web.cjs"
import { useI18next } from "gatsby-plugin-react-i18next"

export { default as Type } from "components/contact-form/type"
export { default as CompanyName } from "components/contact-form/company-name"
export { default as Name } from "components/contact-form/name"
export { default as Email } from "components/contact-form/email"
export { default as Other } from "components/contact-form/other"

export interface FormPage {
  togglePage: (page: number) => void
  currentPage: number
  values: any
  setValues: (state: object) => void
}

interface ContactForm {
  formName: string
  pages: ((props: FormPage) => JSX.Element)[]
  onSubmit: (values: object) => void
  initFormFields: object
}

const ContactForm = ({
  formName,
  pages,
  onSubmit,
  initFormFields,
}: ContactForm) => {
  const { language } = useI18next()
  const [page, setPage] = useState<number>(0)
  const [reversed, setReversed] = useState<boolean>(false)
  const [values, setValues] = useState<object>({
    ...initFormFields,
    "form-name": formName,
    language: language,
  })

  const onClick = useCallback(i => {
    if (i < 0) {
      setReversed(true)
    } else {
      setReversed(false)
    }

    setPage(state => (state + i) % pages.length)

    return () => {
      setPage(0)
      setReversed(false)
    }
  }, [])

  const from: CSSProperties = {
    position: "absolute",
    width: "100%",
    opacity: 0,
    transform: "translate3d(30px,0,0)",
  }
  const leave: CSSProperties = {
    position: "absolute",
    width: "100%",
    opacity: 0,
    transform: "translate3d(-10px,0,0)",
  }

  const transitions = useTransition(page, p => p, {
    from: reversed ? leave : from,
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      position: "relative",
    },
    leave: reversed ? from : leave,
    config: {
      tension: 2000,
      friction: 200,
      mass: 2,
    },
  })

  return (
    <form
      className="contact-form"
      onSubmit={e => {
        onSubmit(values)
        e.preventDefault()
      }}
      name={formName}
      netlify-honeypot="hpfield"
      data-netlify="true"
    >
      <input className="hidden" name="hpfield"></input>
      {Object.keys(values).map(key => (
        // Define hidden fields so Netlify recognizes
        // the form's fields
        <input key={key} name={key} value={values[key]} type="hidden" />
      ))}
      <h3 className="styled">
        <small className="text-xl text-smokeText">{`${page + 1}/${
          pages.length
        }`}</small>
      </h3>
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item]

        return (
          <animated.div key={key} style={{ ...props, zIndex: 10 }}>
            <Page
              togglePage={onClick}
              currentPage={page}
              values={values}
              setValues={setValues}
            />
          </animated.div>
        )
      })}
    </form>
  )
}

export default ContactForm
