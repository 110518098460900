import React, { useCallback } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ArrowCircleRight, ArrowFatLeft } from "components/svg";
import { FormPage } from ".";

const Type = ({togglePage, currentPage, values, setValues}: FormPage) => {
  const {t} = useTranslation('contact');

  const projectId: string = t("New project");
  const partnerId: string = t("Partnership");
  const freelancerId: string = t("Freelancer");
  const otherId: string = t("Something else");

  const toggleNext = useCallback(({target}) => {
    
    setValues(state => ({...state, type: target.value}));
    
    if (target.value === freelancerId) {
      setTimeout(() => togglePage(2), 300);
    } else {
      setTimeout(() => togglePage(1), 300);
    }
  }, []);

  return (
    <div className="grid grid-cols-12 type-selection">
      <h3 className="col-span-12 question-title">{t('typeTitle')}</h3>
      <label className="flex items-center col-span-12 sm:col-span-6 mb-8 md:mb-3 mt-0 md:mt-4 cursor-pointer">
        <input name="type" type="radio" checked={values.type === projectId} value={projectId} onChange={toggleNext}/>
        <span className="checkmark"/>
        <span>{t("I've got a new project")}</span>
      </label>
      <label className="flex items-center col-span-12 sm:col-span-6 mb-8 md:mb-3 mt-0 md:mt-4 cursor-pointer">
        <input name="type" type="radio" checked={values.type === partnerId} value={partnerId} onChange={toggleNext}/>
        <span className="checkmark"/>
        <span>{t('I want to partner up')}</span>
      </label>
      <label className="flex items-center col-span-12 sm:col-span-6 mb-8 md:mb-3 mt-0 md:mt-4 cursor-pointer">
        <input name="type" type="radio" checked={values.type === freelancerId} value={freelancerId} onChange={toggleNext}/>
        <span className="checkmark"/>
        <span>{t("I'm a freelancer")}</span>
      </label>
      <label className="flex items-center col-span-12 sm:col-span-6 mb-8 md:mb-3 mt-0 md:mt-4 cursor-pointer">
        <input name="type" type="radio" checked={values.type === otherId} value={otherId} onChange={toggleNext}/>
        <span className="checkmark"/>
        <span>{t('Something else')}</span>
      </label>
      {currentPage !== 0 && (
        <ArrowFatLeft style={{width: '40px', height: '40px', cursor: 'pointer'}} className="svg-smokeText" onClick={() => togglePage(-1)}/>
      )}
      {values.type !== '' && (
        <div className="absolute right-0 bottom-0" onClick={() => togglePage(1)}>
          <ArrowCircleRight style={{width: '40px', height: '40px'}} className="svg-smokeText cursor-pointer"/>
        </div>
      )}
    </div>
  );
}

export default Type;